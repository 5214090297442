import { memo } from 'react';
import { useWatch, FormProvider } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form';
import { VideoConfiguration } from 'components/video/live/form/VideoConfiguration';
import { Divider, Dialog } from '@schibsted-svp/react-ui';
import useNewsroom from 'hooks/useNewsroom';
import { AudioConfiguration } from 'components/video/live/form/AudioConfiguration';
import { MdCloudQueue } from 'react-icons/md';
import LiveCreationDialogSpinner from 'components/video/live/LiveCreationDialog/LiveCreationDialogSpinner';
import { LiveSchedule } from 'components/video/live/form/LiveSchedule';
import type { LiveScheduleFormData } from 'components/video/live/form/LiveSchedule';
import type { InitialValues } from 'components/video/live/LiveCreationDialog/hooks';
import { MediaLiveInput } from './MediaLiveInput';
import { MediaLiveSource } from './MediaLiveSource';
import { MediaLiveInputFormFooter } from './MediaLiveInputFormFooter';
import { useMediaLiveInputForm, useInitialValues } from './hooks';
import type { MediaLiveInputFormControl } from './types';

import css from './MediaLiveInputForm.module.scss';

interface MediaLiveInputFormProps {
    assetId: number;
    hasEncoder: boolean;
    isEditing: boolean;
    initialValues: InitialValues;
    formControl: MediaLiveInputFormControl;
}

export const MediaLiveInputForm = memo(function MediaLiveInputForm(props: MediaLiveInputFormProps) {
    const { hasEncoder, isEditing, formControl } = props;

    const newsroom = useNewsroom();

    const initialValues = useInitialValues(props);
    const { formApi } = useMediaLiveInputForm(initialValues);

    const {
        control,
        formState: { isSubmitting },
    } = formApi;

    /* RHF has problems with using generic form components so we need to use type assetion here */
    /* @see: https://github.com/react-hook-form/react-hook-form/issues/6726 */
    const liveScheduleFormApi = formApi as unknown as UseFormReturn<LiveScheduleFormData>;

    const access = useWatch({ name: 'access', control });
    const isEncrypted = Boolean(access?.paywall);

    return (
        <FormProvider {...formApi}>
            <Dialog.Section>
                <form className={css.container}>
                    <div className={css.source}>
                        <div className={css.awsInfo}>
                            <MdCloudQueue size={23} className={css.awsInfoIcon} />
                            <span className={css.awsInfoHeader}>AWS Cloud used</span>
                        </div>
                        <Divider margin="15px 0" />
                        <div className={css.sourceInput}>
                            <MediaLiveInput />
                            <MediaLiveSource />

                            {/* MediaLive does not support channels with backup input and digest auth */}
                            {/* @see https://github.schibsted.io/svp/live-api/issues/423 */}
                            {/* <Checkbox {...register('useBackupEncoder')} label="Additional backup channel" /> */}
                            {/* {useBackupEncoder && <MediaLiveSource sourceType={MediaLiveSourceType.BACKUP} />} */}
                        </div>
                    </div>
                    <VideoConfiguration isEncrypted={isEncrypted} enableMediaLiveVertical />
                    {!hasEncoder ? (
                        <>
                            <Divider margin="10px 0" />
                            <LiveSchedule newsroom={newsroom} formApi={liveScheduleFormApi} />{' '}
                        </>
                    ) : null}
                    <AudioConfiguration />
                </form>
                {isSubmitting && <LiveCreationDialogSpinner />}
            </Dialog.Section>
            <MediaLiveInputFormFooter isEditing={isEditing} hasEncoder={hasEncoder} formControl={formControl} />
        </FormProvider>
    );
});
