import { memo, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { MdWarning } from 'react-icons/md';
import classnames from 'classnames/bind';

import { Divider, LabeledContainer, Checkbox } from '@schibsted-svp/react-ui';
import useToggle from 'hooks/useToggle';
import Tooltip from 'components/ui/Tooltip';
import { RadioOptionRHF } from 'components/video/live/form/RadioOption';
import { TimeLengthSelect } from 'components/video/live/form/EncoderInputForm/TimeLengthSelect';
import { AccessDefinitionSelectRHF } from 'components/video/live/form/AccessDefinitionSelect';
import { AdvancedToggleButton } from 'components/video/live/form/AdvancedToggleButton';
import * as validatorHelpers from 'components/video/live/form/validators';
import type { EncoderInputFormData } from '../EncoderInputForm/types';
import type { MediaLiveInputFormData } from '../MediaLiveInputForm/types';
import { hasLogoOverlayOptions, LogoOverlaySelectRHF } from '../LogoOverlaySelect';

import css from './VideoConfiguration.module.scss';
import { CuttingroomChannelSelectRHF } from '../CuttingroomChannelSelect';
import { useGetCuttingroomChannelsQuery } from '../../../../../services/admin-bff-sdk/generated';

const cln = classnames.bind(css);

export interface VideoConfigurationFormData {
    streamQuality: 'standard' | 'normal';
    dvrLength?: number;
    access?: {
        geoblock: boolean;
        paywall?: string;
    };
    profile: {
        HLS1080: boolean;
    };
    livePreview?: boolean;
    vertical?: boolean;
    useBackupEncoder: boolean;
    overlay?: string;
    cuttingroomChannel?: string;
    deinterlace?: boolean;
}

interface VideoConfigurationProps {
    isEncrypted: boolean;
    enableLivePreview?: boolean;
    enableMediaLiveVertical?: boolean;
    enableLiveVertical?: boolean;
    enableDeinterlace?: boolean;
}

export const VideoConfiguration = memo(
    ({
        isEncrypted,
        enableLivePreview = false,
        enableMediaLiveVertical = false,
        enableLiveVertical = false,
        enableDeinterlace = false,
    }: VideoConfigurationProps) => {
        const { newsroom } = useParams();
        const { register, control, trigger, setValue } = useFormContext<
            EncoderInputFormData | MediaLiveInputFormData
        >();

        const [useBackupEncoder, streamQuality, source, inputType] = useWatch({
            name: ['useBackupEncoder', 'streamQuality', 'source', 'inputType'],
            control,
        });
        const disabledBurnInLogo = !source && !inputType;

        const [isAdvancedExpanded, toggleIsAdvancedExpanded] = useToggle(false);
        const [isAdvancedFirstTimeExpanded, setIsAdvancedFirstTimeExpanded] = useState(false);

        useEffect(() => {
            trigger('dvrLength');
        }, [streamQuality, trigger]);

        const toggleAdvancedSection = () => {
            toggleIsAdvancedExpanded();
            setIsAdvancedFirstTimeExpanded(true);
        };

        const isStandardQualitySelected = streamQuality === 'standard';
        const isCustomQualitySelected = streamQuality === 'normal';
        const dvrLengthValidator = isCustomQualitySelected ? { required: validatorHelpers.required } : undefined;

        const cuttingroomChannelsResult = useGetCuttingroomChannelsQuery(
            { newsroom },
            { refetchOnMountOrArgChange: true }
        );

        const cuttingroomChannels = cuttingroomChannelsResult.data?.cuttingroomChannels || [];

        return (
            <div
                className={cln('advancedContainer', {
                    'advancedContainer--expanded': isAdvancedExpanded,
                })}
            >
                <Divider margin="10px 0" />

                <LabeledContainer
                    label="Configure live video"
                    className={cln('advanced', {
                        'advanced--expanded': isAdvancedExpanded,
                        'advanced--touched': isAdvancedFirstTimeExpanded,
                    })}
                >
                    <RadioOptionRHF
                        name="streamQuality"
                        value="standard"
                        label="Standard live video"
                        className={cln('qualityOption', {
                            'qualityOption--selected': isStandardQualitySelected,
                        })}
                    >
                        Normal latency and {isEncrypted ? '6' : '12'} hour DVR
                    </RadioOptionRHF>

                    <RadioOptionRHF
                        name="streamQuality"
                        value="normal"
                        label="Live video with custom DVR"
                        className={cln('qualityOption', {
                            'qualityOption--selected': isCustomQualitySelected,
                        })}
                    >
                        Normal latency
                    </RadioOptionRHF>

                    <LabeledContainer label="DVR Length" width={270} className={css.dvrLength}>
                        <TimeLengthSelect
                            name="dvrLength"
                            control={control}
                            setValue={setValue}
                            defaultLabel="No DVR"
                            disabled={!isCustomQualitySelected}
                            validate={dvrLengthValidator}
                        />
                    </LabeledContainer>

                    <LabeledContainer label="Viewer access" className={css.optionList}>
                        <Checkbox {...register('access.geoblock')} label="Geoblock" />
                        <AccessDefinitionSelectRHF
                            name="access.paywall"
                            disabled={useBackupEncoder}
                            size="compact"
                            className={css.paywallOption}
                        />
                    </LabeledContainer>

                    <LabeledContainer label="Video settings" className={css.optionList}>
                        <Checkbox {...register('profile.HLS1080')} label="1080p quality" />
                        {enableMediaLiveVertical && <Checkbox {...register('vertical')} label="Vertical input" />}
                        {enableLiveVertical && <Checkbox {...register('vertical')} label="Crop to vertical" />}
                        {enableLivePreview && <Checkbox {...register('livePreview')} label="Live preview" />}
                        {enableDeinterlace && <Checkbox {...register('deinterlace')} label="Deinterlace" />}
                    </LabeledContainer>

                    {hasLogoOverlayOptions(newsroom) ? (
                        <LabeledContainer
                            label="Burn-in logo"
                            width={270}
                            className={cln(css.optionList, css.burnInLogo)}
                        >
                            <LogoOverlaySelectRHF
                                name="overlay"
                                control={control}
                                newsroom={newsroom}
                                size="compact"
                                isDisabled={disabledBurnInLogo}
                            />
                            {disabledBurnInLogo ? (
                                <Tooltip theme="dark" placement="top" content="Choose source first">
                                    <div className={css.burnInLogoWarning}>
                                        <MdWarning />
                                    </div>
                                </Tooltip>
                            ) : null}
                        </LabeledContainer>
                    ) : null}

                    {cuttingroomChannels.length ? (
                        <LabeledContainer label="Cuttingroom channel" width={270} className={css.optionList}>
                            <CuttingroomChannelSelectRHF
                                name="cuttingroomChannel"
                                size="compact"
                                cuttingroomChannels={cuttingroomChannels}
                                className={css.cuttingroomChannelOption}
                            />
                        </LabeledContainer>
                    ) : null}
                </LabeledContainer>
                <AdvancedToggleButton onClick={toggleAdvancedSection} isExpanded={isAdvancedExpanded} />
            </div>
        );
    }
);

VideoConfiguration.displayName = 'VideoConfiguration';
